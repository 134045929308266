@import '../../marketplace.css';

.root {
  fill: var(--matterColorAnti);
  margin-left: 3px;
}

.filled {
  fill: var(--marketplaceColor);
  margin-left: 3px;
}
