@import '../../marketplace.css';

.popupSize {
  padding: 0 10px 7px 10px;
}

.popupSize svg {
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);
}
