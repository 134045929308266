@import '../../marketplace.css';

.root {
  border-top: 1px solid  var(--matterColorNegative);

  width: 100%;
  padding: 24px 0 0;
  background-color: var(--matterColorGray);


  @media (--viewportMedium) {
    padding: 0 24px;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 30px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin: 0 12px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 0 0 12px;
  }
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  display: none;
  margin-right: 24px;
  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 30px;
  }
}

.logoLink {
  display: block;
}

.logo {
  object-fit: contain;
  object-position: left center;
  height: 66px;
}


.organizationDescription {
  @apply --marketplaceTinyFontStyles;
}

.organizationCopyright {
  @apply --marketplaceTinyFontStyles;

  display: none;
  margin: 30px 0 15px;
  padding-top: 18px;
  text-align: center;
  border-top: 1px solid var(--borderColorDefault);

  @media (--viewportMedium) {
    display: block;
  }
}

.copyrightLink {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);
  font-weight: var(--fontWeightMedium);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  display: block;
  width: 100%;

  @media (--viewportLarge) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.list {
  display: block;
  flex-shrink: 0;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportSmall) {
    display: flex;
  }
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  line-height: 20px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin: 0 15px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0 30px 0 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 3;
  display: none;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
  }

  @media (--viewportLarge) {
    margin-left: 30px;
    margin-top: -5px;
  }
}

.legalMatters {
  /* Layout */
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.tosAndPrivacy {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: 0;
  margin-bottom: 0;
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.legalLink {
  margin: 0 30px 0 0;
  
  @media (--viewportLarge) {
    margin: 0 0 0 30px;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {

  /* Dimensions */
  height: 60px;
  padding: 0 20px 18px 20px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  flex-shrink: 0;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.privacy,
.terms {
  margin-left: 24px;
}
