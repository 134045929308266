.shoppingCart {
    display: flex;
    align-items: center;
    margin: 24px 0;
    text-decoration: inherit;
    position: relative;
  
    @media (--viewportMedium) {
      margin: 38px 0;
    }
  }
  
  .link {
    color: inherit;
  }
  
  .link:hover {
    text-decoration: none;
  }

  .cartIcon {
      padding-right: 4px;
      display: none;

      @media (--viewportMedium) {
        display: block;
      }
  }