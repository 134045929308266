@import '../../marketplace.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--successColor);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
    fill: var(--marketplaceColor);
  }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    fill: var(--successColor);
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--matterColorDark);
  }
}

.label {
  width: 36px;
  height: 36px;
  padding: 0;
  text-align: center;
}

.checkboxWrapper {
  /* This should follow line-height */
  height: 36px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checked {
  fill: var(--matterColorLight);
  stroke: var(--marketplaceColor);
  stroke-width: 1.5;
}
.checkedSuccess {
  fill: var(--matterColor);
}

.boxSuccess,
.box {
  stroke: var(--matterColorAnti);
}

.textRoot {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
