@import '../../marketplace.css';

.title {
  @apply --marketplaceDefaultFontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0 0 28px 0;
}

.subTitle {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.root {
  width: 100%;

  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionFavoriteBlock {
  display: flex;
  flex-wrap: wrap;
  padding: 0 14px;

  @media (--viewportMedium) {
    margin: 0 -10px;
    padding: 0;
  }
}
