@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  display: flex;
  justify-content: center;
}

.expiration {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: var(--marketplaceColor)
}
