@import '../../marketplace.css';

.root {
  display: block;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.dateInput .DayPicker {
  max-width: 320px;
}
