@import '../../marketplace.css';

.root {
  position: relative;
  width: 100%;
  padding: 0 10px;
  margin: 0 0 30px 0;
  
  @media (--viewportSmall) {
    width: 50%;
  }
  @media (--viewportMedium) {
    width: calc(100%/3);
  }
}

.listingCardItem {

  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.info {
  /* Layout */
  display: flex;
  justify-content: space-between;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
}

.priceValue {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--marketplaceColor);
  text-align: right;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  text-align: right;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceBodyFontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.favouriteButton {
  position: absolute;
  right: 15px;
  top: 5px;
  z-index: 10;
  background-color:var(--matterColorLight);
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='22' viewBox='0 0 19 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.14286 8.07812V17.3594C8.14286 17.6441 7.91498 17.875 7.63393 17.875H6.61607C6.33502 17.875 6.10714 17.6441 6.10714 17.3594V8.07812C6.10714 7.79337 6.33502 7.5625 6.61607 7.5625H7.63393C7.91498 7.5625 8.14286 7.79337 8.14286 8.07812ZM12.3839 7.5625H11.3661C11.085 7.5625 10.8571 7.79337 10.8571 8.07812V17.3594C10.8571 17.6441 11.085 17.875 11.3661 17.875H12.3839C12.665 17.875 12.8929 17.6441 12.8929 17.3594V8.07812C12.8929 7.79337 12.665 7.5625 12.3839 7.5625ZM17.9821 3.4375C18.5443 3.4375 19 3.8992 19 4.46875V4.98438C19 5.26913 18.7721 5.5 18.4911 5.5H17.6429V19.9375C17.6429 21.0766 16.7315 22 15.6071 22H3.39286C2.26855 22 1.35714 21.0766 1.35714 19.9375V5.5H0.508929C0.227873 5.5 0 5.26913 0 4.98438V4.46875C0 3.8992 0.455703 3.4375 1.01786 3.4375H4.17368L5.61641 1.00134C5.79728 0.695935 6.05312 0.443185 6.35903 0.267705C6.66494 0.0922253 7.01049 -3.42658e-06 7.36203 0L11.638 0C11.9895 -3.42658e-06 12.3351 0.0922253 12.641 0.267705C12.9469 0.443185 13.2028 0.695935 13.3836 1.00134L14.8263 3.4375H17.9821ZM6.54775 3.4375H12.4523L11.7121 2.18767C11.6895 2.14949 11.6576 2.1179 11.6193 2.09596C11.5811 2.07403 11.5379 2.0625 11.4939 2.0625H7.50615C7.4622 2.0625 7.41901 2.07403 7.38077 2.09596C7.34253 2.1179 7.31055 2.14949 7.28794 2.18767L6.54775 3.4375ZM15.6071 5.5H3.39286V19.6797C3.39286 19.7481 3.41967 19.8136 3.46739 19.862C3.51511 19.9103 3.57983 19.9375 3.64732 19.9375H15.3527C15.4202 19.9375 15.4849 19.9103 15.5326 19.862C15.5803 19.8136 15.6071 19.7481 15.6071 19.6797V5.5Z' fill='%23C0392B'/%3E%3C/svg%3E%0A");
  background-position: center;
}

.favouriteButton label {
  cursor: pointer;
}

.favouriteButton svg {
  display: none;
}

.favouriteImgLink {
  background:var(--matterColorLight);
  padding: 4px 6px;
}

.listingImage {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
}