@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 32px;
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.errorPrice {
  color: var(--failColor);
  margin: 0;
}

.title {
  @apply --EditListingDescriptionForm_formMargins;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.category {
  @apply --EditListingDescriptionForm_formMargins;
}

.categoryBlock {
  margin-bottom: 30px;

  @media (--viewportSmall) {
    display: flex;
    align-items: center;
  }
}

.categoryButton {
  min-width: 150px;
}

.categoryDescription {
  @media (--viewportSmall) {
    margin-left: 50px;
  }
  @media (--viewportLarge) {
    width: 50%;
  }
}

.submitButton {
  margin: 18px 0 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin: 18px auto 0;
  }
}
