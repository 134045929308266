@import '../../marketplace.css';

.root {
}

.calendarWithSpinner {
  position: relative;
}

.calendar {
  width: 310px;
  height: 300px;
  margin: 8px auto;

  @media (--viewportMedium) {
    width: 620px;
    margin: 0 8px;
  }
  /* Override react-dates default styles to match input styles */

  & :global(.SingleDatePicker) {
    display: block;
  }
  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 0 !important;
    width: 100%;
    min-height: calc(100vh - var(--DateInput_top));
    background-color: var(--matterColorLight);

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 0 !important;
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px;
    }
  }

  & :global(.DateInput_input) {
    display: none;
  }

  & :global(.DayPickerKeyboardShortcuts_show__bottomRight) {
    display: none;
  }

  & :global(.SingleDatePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }
  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 19px;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--matterColorLight);
    border: 0;
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }
  & :global(.DateInput) {
    display: block;
    width: 100%;
  }
  & :global(.DayPicker_weekHeader) {
    top: 57px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: 400;
  }

  & :global(.CalendarMonth_caption) {
    @apply --marketplaceH4FontStyles;
    margin: 1px 0 14px;
    font-weight: 400;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  & :global(.CalendarDay) {
    @apply --marketplaceH5FontStyles;
    color: var(--marketplaceColor);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    border-bottom: 3px solid var(--marketplaceColor);
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }
  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--marketplaceColorLight);
    transition: all 0.2s ease-out;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected) {
    background-color: var(--marketplaceColor);
    background-image: none;
    border-radius: 50%;
  }
  & :global(.CalendarDay__selected .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    /* background-color: var(--marketplaceColor); */
    color: var(--matterColorLight);
    border: none;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered) {
    background-color: transparent;
  }
  & :global(.CalendarDay__after-hovered .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    cursor: pointer;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--matterColorDarkGray);
    border: 0;
  }
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--matterColorDarkGray);
    border: 0;
  }
  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }
  & :global(.DateInput_fang) {
    display: none;
  }
  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }

  & :global(.DateInput__disabled) {
    background: none;
  }
  & :global(.DateInput_input__disabled) {
    @apply --marketplaceDefaultFontStyles;
    @apply --marketplaceInputStyles;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--matterColorNegative);
    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;
    background: none;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="%23E6E6E6" transform="translate(1 1)"><rect stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" x=".292" y="1.459" width="13.417" height="12.25" rx="1"/><path d="M3.208.292V3.21M10.792.292V3.21M.292 4.376h13.416" stroke-linecap="round" stroke-linejoin="round"/><rect x="3.5" y="6.5" width="1" height="1" rx=".5"/><rect x="6.5" y="6.5" width="1" height="1" rx=".5"/><rect x="9.5" y="6.5" width="1" height="1" rx=".5"/><rect x="3.5" y="9.5" width="1" height="1" rx=".5"/><rect x="6.5" y="9.5" width="1" height="1" rx=".5"/><rect x="9.5" y="9.5" width="1" height="1" rx=".5"/></g></g></svg>');
    background-repeat: no-repeat;
    background-position: 0 8px;
    padding-left: 24px;

    &:hover {
      border-bottom-color: var(--matterColorNegative);
    }

    @media (--viewportMedium) {
      padding: 4px 0 9px 24px;
      border-bottom-width: 3px;
    }
  }

  & :global(.DateInput_input__disabled::placeholder) {
    color: var(--matterColorNegative);
    font-style: normal;
  }
}

.mainContainer {
  width: 100%;
  margin: 0 auto;
  padding: 24px 16px;

  @media (--viewportMedium) {
    margin: 0 auto;
    padding: 48px 24px;
  }

  @media (--viewportLarge) {
    margin: 0 auto;
    padding: 80px 24px;
    width: auto;
    /* max-width: 62.5vw; */
  }
}

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0px;
  padding: 0 0 12px;

  @media (--viewportMedium) {
    margin: 0px;
    padding: 0 0 24px;
  }
}

.description {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  padding: 0 0 24px;

  @media (--viewportMedium) {
    font-size: 22px;
  }
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
  }
}

.legend {
  @apply --marketplaceH4FontStyles;
  margin: 45px 0 0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin: 52px 0 0 8px;
    font-size: 22px;
  }

  @media (--viewportLarge) {
    margin: 0 0 0 22px;
  }
}

.error {
  color: var(--failColor);
}

.conteinerSpinner {
  width: calc(100% - 16px);
  height: calc(100% + 6px);
  position: absolute;
  z-index: 2;
  background-color: #ffffffc7;
  display: flex;
  top: 0;
  margin: 0px 8px;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  stroke-width: 3px;
  display: flex;
  margin: 3px 0px;
}
