@import '../../marketplace.css';

:root {
    --MyItineraryPage_containerHeight: calc(100vh - var(--topbarHeightDesktop) - 57px);
}

.mainContainer {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    position: relative;
    flex-direction: row;
    /* padding-top: var(--topbarHeightDesktop); */
    min-height: var(--MyItineraryPage_containerHeight);
  }
}

.contentContainer {
  display: flex;
    flex-direction: column;
    width: 100%;
    padding: 19px 15px 0;
    flex-basis: 50%;

    @media (--viewportMedium) {
      padding: 19px 30px 0 26px;
    }

    @media screen and (min-width: 1024px) {
      flex-basis: 60%;
    }
}

.buttons {
  display: flex;
  justify-content: center;
  padding: 10px 0 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.mapIcon {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  /* background map image */
  background-image: url(./images/map_icon.png);
  background-size: cover;

  border-radius: 0;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0px;
  padding: 0 0 12px;

  @media (--viewportMedium) {
    margin: 0px;
    padding: 0 0 24px;
  }
}

.description {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  font-weight: 500;
  padding: 0 0 24px;
  font-size: 16px;
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
  }
}

.legend {
  @apply --marketplaceH4FontStyles;
  margin: 8px 0;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin: 0 8px;
  }
}

.error {
  color: var(--failColor);
}

.success {
  color: var(--successColor);
}

.conteinerSpinner {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #ffffffc7;
  display: flex;
  top: 0;
  left: 0;
  margin: 0px;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  stroke-width: 3px;
  display: flex;
  margin: 3px 0px;
}

.cancellationModal {
  margin-top: 40px;
}
.cancellationModalButtonHolder {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-top: 50px;
  }
}

.cancellationModalButton {
  @apply --marketplaceButtonStylesPrimary;
}

.cancellationModalButtonNo {
  @apply --marketplaceButtonStylesAction;
  min-height: 56px;
}

.cancellationModalButton,
.cancellationModalButtonNo {
  width: calc(50% - 10px);
  padding: 0;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 41.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    /* top: var(--topbarHeightDesktop); */
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    /* height: var(--ShoppingCart_containerHeight); */
  }

  @media (--viewportLarge) {
    width: 40.5vw;
    max-width: 770px;
  }
}

.mapContainer {
  position: relative;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}
