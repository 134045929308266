@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarMobileMenu_topMargin: 96px;
}

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  /* min-width: 300px;
  background-color: var(--matterColorLight);
  width: 300px; */
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 180px;
}

.contentForLinks {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  padding: 99px 24px 50px;
  background-color: var(--matterColorLight);
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Logout font is smaller and gray since the action is not recommended. */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 6px 0;
  padding: 0;
}

.inbox {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink,
.favoritesLink,
.shoppingCart,
.navigationLinkNotLogged {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}

.navigationLinkNotLogged {
  color: var(--matterColor);
  font-weight: 400;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  @apply --marketplaceButtonStyles;
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;

  flex-grow: 1;
  background: var(--customColorBackground);

  padding: 83px 24px 50px;
  margin: 0;
  font-weight: 600;
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.currentPageNotLogged {
  color: var(--marketplaceColor);

  /* black left border */
  border-left: 5px solid #e52534;
  margin-left: -24px;
  padding-left: 19px;
}

.favoritesLink > div {
  margin: 0;
}

.shoppingCart > div {
  margin: 0;
}

.favoritesLink svg {
  display: none;
}

.shoppingCart svg {
  display: none;
}