@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.phoneNumberLabel {
  font-weight: var(--fontWeightSemiBold);
}

.phoneNumber {
  @apply --marketplaceModalPasswordMargins;
}

.signUpSelect {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.signupBottomWrapper {
  display: flex;
  justify-content: center;
  margin: 38px 0 18px;
}

.signupbottomWrapperText {
  margin: 0;
}

.signupBottomButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 -9px;
}

.signupButton {
  margin: 0 9px;
  font-weight: var(--fontWeightMedium);
  position: relative;
}