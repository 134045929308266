@import '../../marketplace.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  @apply --marketplaceModalRootStyles;

  @media (--viewportMedium) {
    @apply --backgroundImage;
    min-height: 100vh;
  }
}

.content {
  @apply --marketplaceModalBaseStyles;
  padding-top: 29px;
  position: relative;
}

/* ================ Tabs & Form ================ */

.tabs {
  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.tab {
  @apply --marketplaceModalTitleStyles;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.termsHeading {
  @apply --marketplaceH1FontStyles;
  margin: 0 0 19px 0;

  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  @apply --marketplaceModalCloseStyles;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.loginTitle {
  display: flex;
  justify-content: center;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.9px;
  font-size: 30px;
  margin: 0 0 14px 0;
}

.loginSubTitle {
  max-width: 418px;
  margin: 0 auto 14px auto;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
}

/* ================ Errors ================ */

.error {
  @apply --marketplaceModalErrorStyles;
}

.arrowIcon {
  margin-right: 7px;
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.backButton {
  display: flex;
  align-items: center;
  margin-bottom: 33px;
  color: var(--marketplaceColor);
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

span.activeResendSms:hover {
  cursor: pointer;
}

span.activeResendSms {
  text-decoration: none;
  width: 30%;
  font-size: 14px;
  color: var(--marketplaceColor)
}

span.disabledResendSms {
  text-decoration: none;
  width: 30%;
  font-size: 14px;
}

span.resendSms:hover {
  color: #bd001c;
  text-decoration: none;
}

.resendSmsMessage {
  margin-top: 20px;
  font-size: 14px;
}

.expiration {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: var(--marketplaceColor);
  margin-bottom: 20px;
}
