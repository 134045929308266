@import '../../marketplace.css';

.heading {
  margin: 5px 0 18px 0;
  font-size: 48px;
  line-height: 1;

  @media (--viewportMedium) {
    margin: 14px 0 24px 0;
  }
}