@import '../../marketplace.css';

.table {
  width: 100%;
  border-collapse: collapse;
}

.tableHeadingRow {
  width: 100%;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid #787777;
  text-align: left;
}

.tableDataRow {
  width: 100%;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid #e6e6e6;
  text-align: left;

   &:hover {
     background-color: #e7e7e7;
   }
}

.tableDataRowActive {
  width: 100%;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid #e6e6e6;
  text-align: left;
  background-color: #e7e7e7;
}

.itemHeader {
  font-size: 16px;
  line-height: 20px;
  color: #787777;
  font-style: normal;
  font-weight: normal;
}

.itemTable {
  font-size: 16px;
  line-height: 20px;
  color: #4a4a4a;
  font-style: normal;
  font-weight: normal;
}

.itemContainerFirst {
  padding-left: 10px;

  @media (min-width: 1700px) {
    padding-left: 30px;
  }
}

.itemContainerThird {
  min-width: 75px;
}

.itemContainerButton {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  align-items: start;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0 0;
    align-items: center;
  }
}

.buttonEditDelete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: none;
  padding: 0 0 4px;
  cursor: pointer;

  @media (--viewportMedium) {
    padding: 0 4px 0;
  }
}

.buttonImage {
  padding: 0 5px 2px 0;
}

.buttonEditText {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3c5a9a;
}

.buttonDeleteText {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #c0392b;
}

.buttonConfirm {
  border: none;
  border-radius: 4px;
  background: #2ba05d;
  padding: 6px 32px;
  cursor: pointer;
  min-width: 123px;
  display: flex;
  justify-content: center;
}

.buttonConfirmDisabled {
  border: none;
  border-radius: 4px;
  background: var(--matterColorNegative);
  padding: 4px 32px;
  cursor: not-allowed;
}

.buttonConfirmText {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.cartTotalContainer {
  display: flex;
  flex-direction: column;
  padding: 12px 32px 13px 30px;
  background: #f6f6f6;
}

.rowTotalContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rowText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #4a4a4a;
}

.price {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: #4a4a4a;
}

.cardContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #e7e7e7;
  }
}

.cardContainerMobileActive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.6);
  background-color: #e7e7e7;
}

.rowContainerMobile {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 0 5px;
}

.itemFistColumnMobile {
  width: 145px;
}

.itemSecondColumnMobile {
  width: 100%;
}

.rowContainerButtonMobile {
  display: flex;
  flex-direction: row;
  padding: 4px 0;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @media (--viewportMedium) {
    padding: 12px 0 0;
  }
}

.spinner {
  width: 18px;
  height: 18px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
  display: flex;
  margin: 3px 0px;
}
