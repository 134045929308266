@import '../../marketplace.css';

.pageTitle {
  @apply --marketplaceH2FontStyles;
  margin-bottom: 40px;
}

.staticPageWrapper {
  width: 100%;
  max-width: 1028px;
  margin: 24px auto;
  padding: 0 24px;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 30px auto;
    padding: 0;
  }
}

.coverImage {
  max-width: 100%;
  border-radius: 4px;
  object-fit: cover;
  margin: 40px 0;

  @media (--viewportMedium) {
    margin: 0 0 40px 40px;
    height: 160px;
  }

  @media (--viewportLarge) {
    height: 300px;
  }
}

.contentWrapper {
  margin-bottom: 40px;

  @media (--viewportMedium) {
    margin-bottom: 90px;
    display: flex;
  }
}

.contentMain {
  margin: 0;
}

.contentMain p {
  margin-top: 0;
}

.containerLink {
  padding: 0 0 12px;
}

.linkPdf {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--matterColor);
  text-decoration: underline;
}
