.favorite {
  display: flex;
  align-items: center;
  margin: 24px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    margin: 38px 0;
  }
}

.link {
  color: inherit;
}

.link:hover {
  text-decoration: none;
}
