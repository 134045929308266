@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingPoliciesForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.title {
  @apply --EditListingPoliciesForm_formMargins;
}

.policy {
  @apply --EditListingPoliciesForm_formMargins;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.cancellationOptionTitle {
  display: block;
  min-width: 150px;

  @media (--viewportSmall) {
    display: inline-block;
  }
}

.submitButton {
  margin: 30px 0 24px;
  flex-shrink: 0;

  @media (--viewportSmall) {
    margin: 50px 0 24px;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin: 50px auto 0;
  }
}

.note {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 30px;
}
