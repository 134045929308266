@import '../../marketplace.css';

.title {

  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 30px 0;
  padding: 0 24px;
  width: 100%;

  @media (--viewportLarge) {
    max-width: 1056px;
    margin: 0 auto;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 20px auto 30px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}
