@import '../../marketplace.css';

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0 0 40px;
  text-align: center;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  position: relative;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
}

.stepNumber {
  font-size: 48px;
  line-height: 20px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
  opacity: 0.08;

  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 72px;
    line-height: 24px;
  }
}

.stepDescription {
  @apply --marketplaceBodyFontStyles;
  color: var(--matterColorDarkGray);
}
