@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ContactDetailsForm_infoText: {
    @apply --marketplaceH4FontStyles;
    font-size: 16px;
    color: var(--successColor);

    display: inline-block;
    padding-left: 18px;
    background-repeat: no-repeat;
    background-position: top 10px left;

    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-bottom: 1px;
    }
  }
}


.root {
  
  @media (--viewportMedium) {
    @apply --marketplaceModalFormRootStyles;
    justify-content: space-between;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.password {
  margin: 0 0 21px 0;
}

.phoneNumber {
  margin: 0 0 20px 0;
}

.phoneNumberLabel {
  font-size: 16px;
  line-height: 1.5;
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 5px 0;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceH5FontStyles;
  margin-top: 0;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}

.expiration {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: var(--marketplaceColor);
  margin-bottom: 20px;
}

.sendCodeBtn {
  @apply --marketplaceButtonStylesAction;
  padding: 0;
  min-height: 56px;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
}


.resendSmsMessage {
  margin-bottom: 17px;
  font-size: 14px;
  text-align: center;
}

.submitBtn {
  @apply --marketplaceButtonStylesAction;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  padding: 0;
  min-height: 56px;

  
  @media (--viewportMedium) {
    position: absolute;
    width: calc(100% - 100px);
    z-index: 2;
    bottom: 55px;
    left: 50px;
  }


  &:focus {
    background: var(--matterColorLight);
    border-color: var(--marketplaceColor);
    color: var(--marketplaceColor);
  }

  & svg {
    stroke: var(--marketplaceColor);
  }
}

.phoneChangedText {
  @apply --ContactDetailsForm_infoText;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');
  font-size: 14px;
}
