@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.title,
.heading,
.subheading,
.subheadingGuest,
.timezonePicker,
.guestContainer,
.week,
.hasEntries,
.submitButton {
  padding-left: 30px;
  padding-right: 30px;

  @media (--viewportSmall) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.heading {
  @apply --marketplaceH4FontStyles;
}

.subheading {
  @apply --marketplaceDefaultFontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  padding-top: 19px;
  padding-bottom: 5px;
}

.error {
  color: var(--failColor);
}

.timezonePicker {
  margin-bottom: 24px;
}

.week {
  display: flex;
  flex-direction: column;

  padding-top: 16px;
  margin-bottom: 23px;

  @media (--viewportLarge) {
    margin-bottom: 60px;
  }
}

.weekDay {
  @apply --marketplaceTabNavHorizontalFontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 4px 0 19px;
  border-top: solid 1px var(--matterColorNegative);

  &:last-child {
    border-bottom: solid 1px var(--matterColorNegative);
  }

  @media (--viewportLarge) {
    width: 360px;
    margin: 0 auto;
  }
}

.hasEntries {
  background-color: var(--matterColorLight);
}

.dayOfWeek {
  width: 100%;
  padding-top: 8px;
  @media (--viewportSmall) {
    width: 200px;
  }
}

.timePicker {
  width: 100%;
}

.fieldWrapper {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  padding-bottom: 6px;

  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 39px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid var(--matterColorNegative);
    z-index: 0;
  }
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* parent aka fieldWrapper is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;

  z-index: 1;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
  stroke: none;
  fill: var(--marketplaceColorGrayText);

  &:hover {
    fill: var(--matterColor);
  }

  /* align icon with the select fields */
  padding: 2px 0 11px;
}

.dashBetweenTimes {
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 8px;
}

.buttonSetHours {
  padding-top: 8px;
  color: var(--marketplaceColorSecondary);
}

.buttonAddNew {
  @apply --marketplaceDefaultFontStyles;
  color: var(--marketplaceColor);
}
.submitButton {
  margin-top: auto;
  flex-shrink: 0;
  /* Mobile phones introduce bottom-bar, for which we need to give 96px vertical space */
  padding-bottom: 96px;
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: -16px;
  }
  .title {
    margin-bottom: 32px;
  }

  .buttonAddNew {
    margin-bottom: 6px;
  }

  .submitButton {
    padding-bottom: unset;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }
}

.fieldSelect {
  @apply --marketplaceTabNavHorizontalFontStyles;
  background: none;
  padding-right: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 42px;

  &:select {
    margin-right: 0;
  }

  &:disabled {
    border-bottom-color: var(--matterColorAnti);
    color: var(--matterColorAnti);
    cursor: default;
  }
}
.hoursOfOperationTitle {
  margin-bottom: 0;
  padding: 0 60px;
  color: var(--matterColorAnti);
}

.subheadingGuest {
  @apply --marketplaceDefaultFontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  padding-top: 19px;
  padding-bottom: 5px;
  font-size: 22px;
}

.guestContainer {
  margin-bottom: 24px;
}

.numberOfGuestsBlock {
  margin: 0;
}

.labelGuest {
  margin: 0;
  padding: 10px 0 0;
}

.fieldHide {
  display: none;
}