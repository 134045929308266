@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.numberOfGuestsWrapper {
  display: flex;
}

.numberOfGuestsBlock {
  width: 150px;
  margin-right: 40px;
}

.submitButton {
  margin: 50px 0 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin: 50px auto 0;
  }
}

.subTitle {
  margin: 38px 0 28px;
}

.duration select {
  border-bottom-color: var(--marketplaceColor);
}