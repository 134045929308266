@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 110px;
}

.root {
}

.linksContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  background-color: var(--matterColorLight);
}

.linksWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.link {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--matterColor);
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.banerContainer {
  position: absolute;
  width: 100%;
  min-height: 50px;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.banerText {
  @apply --marketplaceH4FontStyles;
  font-size: 18px;
  background-color: var(--matterColorLight);
  color: var(--marketplaceColor);
  padding: 0 10px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 140px;
  }
}

.heroWithBanner {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;
  padding-top: 80px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
    padding-top: 140px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 140px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section:last-child .sectionContent {
  margin-bottom: var(--LandingPage_sectionMarginTopLarge);
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 0 auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
}
