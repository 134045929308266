@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ContactDetailsForm_infoText: {
    @apply --marketplaceH4FontStyles;
    font-size: 16px;
    color: var(--successColor);

    display: inline-block;
    padding-left: 18px;
    background-repeat: no-repeat;
    background-position: top 10px left;

    margin-top: 11px;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 14px;
      margin-bottom: 1px;
    }
  }
}

.root {
}

.contactDetailsSection {
  margin-top: 16px;
  margin-bottom: 46px;
  padding-top: 6px;

  @media (--viewportMedium) {
    padding-top: 0;
  }
}

.phone {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.confirmChangesSection {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  margin-bottom: 60px;
  height: 100%;
  opacity: 1;
}

.emailVerified {
  @apply --ContactDetailsForm_infoText;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');
}

.emailUnverified {
  @apply --ContactDetailsForm_infoText;
  color: var(--matterColorAnti);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.pendingEmailUnverified {
  @apply --ContactDetailsForm_infoText;
  color: var(--failColor);
  width: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.checkInbox {
  color: var(--matterColor);
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.helperLink {
  color: var(--matterColor);
  border-bottom: solid 1px var(--matterColor);
  line-height: 20px;
  margin: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.tooMany {
  color: var(--matterColorAnti);
}

.emailSent {
  color: var(--matterColor);
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorAnti);
  font-size: 18px;
  line-height: 1;

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.bottomWrapper {
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.smsUnverified {
  @apply --ContactDetailsForm_infoText;
  color: var(--matterColorAnti);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.smsUnverified a:hover {
  text-decoration: none;
}

.verifyUnvisible {
  display: none;
}

.verifyVisible {
  display: block;
}
