@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ShoppingCart_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
    z-index: initial;
}
  
.topbar {
    @media (--viewportMedium) {
      position: fixed;
      top: 0;
      left: 0;
      /* We need to raise Topbar above .container */
      z-index: 15;
    }
}

.container {
    /* Layout */
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  
    @media (--viewportMedium) {
      position: relative;
      flex-direction: row;
      padding-top: var(--topbarHeightDesktop);
      min-height: var(--ShoppingCart_containerHeight);
    }
}

.paymentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 19px 15px 0;
    flex-basis: 50%;

    @media (--viewportMedium) {
      padding: 19px 30px 0 47px;
    }

    @media screen and (min-width: 1024px) {
      flex-basis: 60%;
    }
}

.headingContainer {
    margin: 0;
    padding: 0;
}

.headingTitle {
    @apply --marketplaceH3FontStyles;
    line-height: 56px;
    margin: 0;
    padding: 0;
}

.headingText {
    @apply --marketplaceTxTransitionFontStyles;
    /* line-height: 56px;
    margin: 0; */
    font-weight: normal;

    @media (--viewportMedium) {
        font-size: 16px;
      }
}

.paymentMethodsContainer {
  padding: 0 0 89px;
  max-width: 517px;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 41.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    /* top: var(--topbarHeightDesktop); */
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    /* height: var(--ShoppingCart_containerHeight); */
  }

  @media (--viewportLarge) {
    width: 40.5vw;
    max-width: 770px;
  }
}

.mapContainer {
  position: relative;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  padding: 10px 0 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.mapIcon {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  /* background map image */
  background-image: url(./images/map_icon.png);
  background-size: cover;

  border-radius: 0;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;

  @media (--viewportMedium) {
    margin: 10px 0 12px 0;
  }
}
.orderError {
  color: var(--failColor);
  text-align: center;
}
.notFoundError {
  color: var(--attentionColor);
}

.speculateError {
  color: var(--failColor);
  margin: 19px 0 1px 0;

  @media (--viewportLarge) {
    margin: 28px 48px 0 48px;
  }
}