@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --VerifiedUser_infoText: {
    @apply --marketplaceH4FontStyles;
    font-size: 16px;
    color: var(--successColor);

    display: inline-block;
    padding-left: 18px;
    background-repeat: no-repeat;
    background-position: top 10px left;

    margin-top: 11px;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 14px;
      margin-bottom: 1px;
    }
  }
}

.profilePageWrapper {
  display: flex;
  flex-direction: column-reverse;
  margin: 60px auto 120px;
  width: 100%;

  @media (--viewportLarge) {
    width: auto;
    flex-direction: row;
    padding: 0 30px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0;
  }
}

.centralContentWrapper {
  padding: 0 26px;
  border-right: 1px solid var(--borderColorDefault);

  @media (--viewportLarge) {
    padding: 0 52px 0 0;
  }

  @media (--viewportLargeWithPaddings) {
    width: 752px;
  }
}

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.aside {
  padding: 0 26px;
  box-shadow: none;
  background: none;
  border: none;

  @media (--viewportLarge) {
    padding: 0 0 0 52px;
  }
}

.asideContent {
  width: 100%;

  @media (--viewportSmall) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    width: 256px;
    flex-direction: column;
    align-items: center;
    border-bottom: none;
  }
}

.avatarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin: 0 0 30px 0;
  max-width: 100%;

  @media (--viewportSmall) {
    display: block;
    margin: 0 30px 30px 0;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.avatarBlock {
  @media (--viewportSmall) {
    display: flex;
  }
}

.avatar {
  width: 140px;
  height: 140px;
  flex-shrink: 0;
}

.mobileHeading {
  @apply --marketplaceH3FontStyles;
  flex-shrink: 0;
  margin: 20px 0 0 0;
  word-break: break-all;

  @media (--viewportSmall) {
    margin: 47px 0 0 0;
  }
  @media (--viewportMedium) {
    margin: 38px 0 0 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.mobileHeading span {
  word-break: break-all;
}

.editLinkMobile {
  max-width: 140px;
  margin: 20px auto 0;
  display: block;
  text-align: center;

  @media (--viewportSmall) {
    margin: 0 20px 0 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
    margin-top: 20px;
  }
}

.desktopHeading {
  @apply --marketplaceH3FontStyles;
  display: none;
  max-width: 100%;
  word-break: break-all;

  @media (--viewportLarge) {
    display: block;
    margin: 2px 0 10px 0;
  }
}

.desktopHeading span {
  word-break: break-all;
}

.bio {
  @apply --marketplaceDefaultFontStyles;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  @apply --clearfix;
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    @apply --marketplaceH5FontStyles;
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listing {
  width: 100%;

  & :global(.favouriteButton) {
    display: none;
  }

  /* Single column in mobile */
  margin-top: 34px;
  &:first-of-type {
    margin-top: 18px;
  }

  @media (--viewportMedium) {
    /* Two columns in desktop */
    width: calc(50% - 12px);
    margin-bottom: 36px;

    /* Horizontal space */
    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 12px;
    }

    /* Vertical space */
    margin-top: 0;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }
}

.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
}

.desktopReviews {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    max-width: 600px;
    margin-bottom: 0;
  }
}

.desktopReviewsTitle {
  @apply --marketplaceH5FontStyles;
  color: inherit;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

.overallRatingWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.overallRating {
  line-height: 1;
}

.overallRatingStar {
  width: 19px;
  height: 19px;
}

.overallRatingCounter {
  margin: 0;
  padding: 6px 0 0 6px;
}

.verifiedUserBadge {
  @apply --VerifiedUser_infoText;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');
  margin: -15px 0 0;
}

.characteristicsList {
  margin: 0;

  @media (--viewportLarge) {
    width: 100%;
    margin: 30px 0 0;
  }
}

.characteristicsListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.characteristicsTitle {
  @apply --marketplaceDefaultFontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorDark);
}

.characteristicsRating {
  line-height: 1;
}

.characteristicsRatingStar {
  width: 19px;
  height: 19px;
}

.characteristicsRatingStarAltColor {
  fill: var(--marketplaceColor);
}

.showMorelink {
  display: block;
  text-align: center;
  margin-top: 20px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.videoContainer {
  display: flex;
  width: 100%;
  padding: 10px 0 50px;
}