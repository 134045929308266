@import '../../marketplace.css';

.root {
  
  @media (--viewportMedium) {
    @apply --marketplaceModalFormRootStyles;
    justify-content: space-between;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.password {
  margin: 0 0 21px 0;
}

.bottomWrapper {
  margin-top: 30px;
}

.phoneNumber {
  /* margin: 0 0 20px 0; */
  display: none;
}

.phoneNumberLabel {
  font-size: 16px;
  line-height: 1.5;
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 5px 0;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  display: flex;
  justify-content: center;
}

.expiration {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: var(--marketplaceColor);
  margin-bottom: 20px;
}

.sendCodeBtn {
  @apply --marketplaceButtonStylesAction;
  padding: 0;
  min-height: 56px;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
}

span.activeResendSms:hover {
  cursor: pointer;
}

span.activeResendSms {
  text-decoration: none;
  width: 30%;
  font-size: 14px;
  color: var(--marketplaceColor)
}

span.disabledResendSms {
  text-decoration: none;
  width: 30%;
  font-size: 14px;
}

span.resendSms:hover {
  color: #bd001c;
  text-decoration: none;
}

.resendSmsMessage {
  margin-bottom: 17px;
  font-size: 14px;
  text-align: center;
}

.submitBtn {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    position: static;
  }

  &:focus {
    background: var(--marketplaceColor);
  }
}
