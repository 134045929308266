@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.root {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {

  padding: 36px;

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  text-align: center;

  @apply --SectionHero_animation;
  animation-delay: 0.5s;
  margin: 0 0 24px 0;
}

.heroSubTitle {
  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightRegular);
  text-align: center;
  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  @apply --SectionHero_animation;
  animation-delay: 0.65s;
}

.heroButton {
  @apply --marketplaceButtonStyles;
  @apply --SectionHero_animation;
  animation-delay: 0.8s;

  display: block;
  text-transform: uppercase;

  @media (--viewportSmall) {
    width: 328px;
    margin: 0 auto;
  }
  

}

.heroActionButtonsWrapper {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    padding: 0 50px;
  }
}

.heroButtonAction {
  @apply --marketplaceButtonStylesAction;
  @apply --SectionHero_animation;
  animation-delay: 0.95s;

  display: block;
  margin: 32px auto 0;

  @media (--viewportSmall) {
    width: 328px;
  }

  @media (--viewportMedium) {
    width: 270px;
    margin: 0 10px;
  }

  @media (--viewportLarge) {
    margin: 0 auto;
  }

  &:nth-child(1) {
    order: 2;
    
    @media (--viewportMedium) {
      order: 1;
    }
  }

  &:nth-child(2) {
    order: 3;
    
    @media (--viewportMedium) {
      order: 2;
    }
  }

  &:nth-child(3) {
    order: 1;
    
    @media (--viewportMedium) {
      order: 3;
    }
  }
}