@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --VerifiedUser_infoText: {
    @apply --marketplaceH4FontStyles;
    font-size: 16px;
    color: var(--successColor);

    display: inline-block;
    padding-left: 18px;
    background-repeat: no-repeat;
    background-position: top 10px left;

    margin-top: 11px;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 14px;
      margin-bottom: 1px;
    }
  }
}

.profilePageWrapper {
  display: flex;
  flex-direction: column;
  margin: 20px auto 60px;
  width: 100%;

  @media (--viewportMedium) {
    width: auto;
    padding: 0;
    /* width: 650px; */
  }
}

.centralContentWrapper {
  padding: 0 16px;

  @media (--viewportMedium) {
    padding: 30px 32px 0;
  }

  @media (--viewportLargeWithPaddings) {
    width: 752px;
  }
}

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.desktopHeading {
  @apply --marketplaceH2FontStyles;
  max-width: 100%;
  word-break: break-all;
  margin: 0;

  @media (--viewportLarge) {
    display: block;
  }
}

.desktopHeading span {
  word-break: break-all;
}

.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
}

.desktopReviews {
  @media (--viewportMedium) {
    margin-bottom: 48px;
    width: 650px;
  }

  @media (--viewportLarge) {
    width: 800px;
    margin-bottom: 0;
  }
}

.desktopReviewsTitle {
  @apply --marketplaceH5FontStyles;
  color: inherit;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}